import React, { useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Trash, Trash2, Trash3, TrashFill } from 'react-bootstrap-icons';
import Select from 'react-select';

export default function CustomTimePickerHourly({ selectedReportTimes, handleSelectedReportTimes, selectedFrequency }) {
  const [timeSlots, setTimeSlots] = useState(selectedReportTimes || [{ hour: 'HH:MM' }]);

  const hours = (Array.from({ length: 24 }, (_, i) => ({
    value: `${i.toString().padStart(2, '0')}:00`,
    label: `${i.toString().padStart(2, '0')}:00`
  })));

  const isTimeSlotComplete = (timeSlot) =>
    timeSlot.hour !== 'HH:MM';

  const handleAddTimeSlot = () => {
    if (isTimeSlotComplete(timeSlots[timeSlots.length - 1])) {
      setTimeSlots([...timeSlots, { hour: 'HH:MM' }]);
    }
  };

  const handleTimeChange = (index, type, selectedOption) => {
    setTimeSlots((prevTimeSlots) => {
      const newTimeSlots = [...prevTimeSlots];
      newTimeSlots[index] = { ...newTimeSlots[index], [type]: selectedOption.value };

      const filteredTimeSlots = newTimeSlots
        .filter(isTimeSlotComplete)
        .map(timeSlot => `${timeSlot.hour}`);

      handleSelectedReportTimes(filteredTimeSlots);

      return newTimeSlots;
    });
  };

  const removeTimeItem = (index) => {
    const arr = [...timeSlots];
    arr.splice(index, 1);
    setTimeSlots(arr);

    handleSelectedReportTimes(arr.map(i => i.hour))
  }

  return (
    <div>
      {timeSlots.map((timeSlot, index) => (
        <div key={index} style={styles.container}>
          <div style={{
            ...styles.fakeSelectContainer,
            backgroundColor: selectedFrequency === null ? '#f8f8f8' : '#ffffff'
          }}>
            <Select
              options={hours}
              className="basic-single no_border"
              classNamePrefix="select"
              placeholder="HH:MM"
              onChange={(selectedOption) => handleTimeChange(index, 'hour', selectedOption)}
              value={hours.find(h => h.value === timeSlot.hour)}
              styles={selectStyles}
              components={{ IndicatorSeparator: () => null, DropdownIndicator: () => null }}
            />
          </div>
          {index === 0 ?
            null
            :
            <Trash3 color='#8020CF' size={20} style={{ marginLeft: "20px", cursor: "pointer" }} onClick={() => removeTimeItem(index)} />
          }
        </div>
      ))}
      <div style={styles.addTimeSlotContainer}>
        <button
          type="button"
          onClick={handleAddTimeSlot}
          style={styles.addButton}
        >
          Add Report Time
        </button>
      </div>
    </div>
  );
}



// Styles
const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  fakeSelectContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    border: '1px solid #ccc',
    padding: '5px 10px',
    width: '120px',
  },
  separator: {
    fontSize: '16px',
    margin: '0 5px',
  },
  addButton: {
    padding: '5px 0',
    fontSize: '14px',
    color: '#6200ee',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  addReportTimeContainer: {
    textAlign: 'left',
    marginTop: '10px',
  },
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '30px',
    height: '30px',
    border: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    border: 0,
    boxShadow: 'none',
  }),
  container: (provided) => ({
    ...provided,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
    padding: 0,
    width: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  menuitem: (provided) => ({
    ...provided,
    padding: '5px 0',
    textAlign: 'center',
    width: '100px',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#000',
    cursor: 'pointer',
    padding: '5px 0',
    textAlign: 'center',
    width: '100px',
  }),
};
