import React from 'react';
import { Modal } from 'react-bootstrap';
import DummySnapshot from '../../assets/images/dummySnapshot.png'

export default function SnapshotModal(props) {

  return (
    <Modal show={props.show} onHide={props.handleClose} size='lg' centered className='snapshot-modal'>
      <Modal.Body>
        <img src={DummySnapshot} alt="snapshot" className='snapshot-img' />

        <div className='product-info'>
          <div className='name-header'>PRODUCT NAME:</div>
          <div className='name'>{props.item?.sku}</div>
          {props.item?.class_title === '0' ?
            <div className='status no'>No Stock</div>
            :
            props.item?.class_title === '0-25' ?
              <div className='status critical'>Critical Stock</div>
              :
              props.item?.class_title === '25-50' ?
                <div className='status low'>Low Stock</div>
                :
                props.item?.class_title === '50-75' ?
                  <div className='status medium'>Medium Stock</div>
                  :
                  props.item?.class_title === '75-100' ?
                    <div className='status high'>High Stock</div>
                    :
                    props.item?.class_title === 'overfill' ?
                      <div className='status overfill'>Overfill</div>
                      :
                      null
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}
