import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import CustomTimePickerHourly from '../CustomTimePickerHourly';
import {
  CaretDownFill,
  CaretUpFill
} from 'react-bootstrap-icons';

export default function AddDashboardReportModal(props) {
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [selectedReportTimes, setSelectedReportTimes] = useState(null);

  const closeModal = () => {
    setSelectedDashboard(null);
    setSelectedFrequency(null);
    setSelectedReportTimes(null);
    props.handleClose();
  }

  const handleAddAlert = (reportTimes) => {
    props.handleDone(reportTimes);
    closeModal();
  }

  useEffect(() => {
    if(selectedDashboard !== null && selectedFrequency !== null && selectedReportTimes !== null){
      setIsSaveDisabled(false);
    }
    else{
      setIsSaveDisabled(true);
    }
  }, [selectedDashboard, selectedFrequency, selectedReportTimes])

  return (
    <Modal className='add-report-modal' show={props.show} onHide={closeModal} centered>
      <Modal.Header className='text-center' closeButton>
        <Modal.Title className="w-100">
          Add Periodic Report
        </Modal.Title>

      </Modal.Header>
      <span style={{ textAlign: 'center' }}> You can add periodic report for dashboards.</span>
      <Modal.Body>
        <p className="select-asset-label">Dashboard</p>
        <Select
          name="alertOptions"
          options={props.dashboardOptions}
          components={{ DropdownIndicator: ({ selectProps }) => selectProps.menuIsOpen ? <CaretUpFill className='select-icon me-2' /> : <CaretDownFill className='select-icon me-2' /> }}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Dashboard"
          onChange={(item) => setSelectedDashboard(item)}
          //value={selectedReportAlert === null ? null : props.frequencyOptions.find(f => f.value === selectedFrequency?.value)}
        />

        <p className="select-asset-label">Report Frequency</p>
        <Select
          name="alertOptions"
          options={props.frequencyOptions}
          components={{ DropdownIndicator: ({ selectProps }) => selectProps.menuIsOpen ? <CaretUpFill className='select-icon me-2' /> : <CaretDownFill className='select-icon me-2' /> }}
          className="basic-single table-select"
          classNamePrefix="select"
          placeholder="Select Frequency"
          onChange={(item) => setSelectedFrequency(item)}
          //value={selectedReportAlert === null ? null : props.frequencyOptions.find(f => f.value === selectedFrequency?.value)}
        />
        {
          selectedFrequency?.value === 'DAILY' &&
          <>
            <p className="select-asset-label">Report Time <i>(UTC)</i></p>
            <CustomTimePickerHourly
              selectedReportTimes={selectedReportTimes}
              handleSelectedReportTimes={setSelectedReportTimes}
              selectedFrequency={selectedFrequency}
            />
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        {isSaveDisabled ?
          <Button className="add-report-disabled-btn w-50">Save</Button>
          :
          <Button className="add-report-active-btn w-50" onClick={() => handleAddAlert(selectedReportTimes)}>Save</Button>
        }
      </Modal.Footer>
    </Modal>
  )
}
